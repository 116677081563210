import axios from 'axios';
import emailjs from "@emailjs/browser";
import { validateEmail, variantContactWhiteList } from '../utils';
import Swal from 'sweetalert2';

export const linkSheet = 'https://script.google.com/macros/s/AKfycbygJ0KmrSz3MvJ_tQf91ic0-Z18fOz3Zcf83CWsilkujb_w-XFaOk8LbDKtlQ4crN-J/exec';
// export const linkSheet = 'https://script.google.com/macros/s/AKfycbz63oC1jkQbFbLRF2g7EPLct2PjraJmj27fckI3tDfpKZsdGWJ_FhcOmpJkHLgZqKF5/exec';

function error(errorTitle) {
  Swal.fire({
    position: "center",
    icon: "error", 
    title: errorTitle,
    showConfirmButton: false,
    timer: 2000,
  });
}

function success() {
  Swal.fire({
    position: "center",
    icon: "success",
    title: "Sent with success",
    showConfirmButton: false,
    timer: 2000,
  });
}

function validateData(whiteList, sheet) {
  const isValid = validateEmail(whiteList.email)
  if(sheet === variantContactWhiteList.CONTACT) {
    if(
      !isValid || (
        whiteList.name == '' ||
        whiteList.topic == '' ||
        whiteList.message == ''
      )){
      error("Fill in all contact fields");
      return false;
    }
  }

  if(!isValid) {
    error("Invalid email");
    return false;
  } 

  return true;
}

export async function saveContact(whiteList, sheet) {
  const sheets = linkSheet;
  const isValid = validateData(whiteList, sheet)
  
  if (!isValid) {
    return false;
  }
  
  const templateParms = {
    email: whiteList.email,
    name: whiteList.name,
    topic: whiteList.topic,
    message: whiteList.message,
    sheet: sheet
  };

  await axios.request({
    url: sheets,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
    },
    data: JSON.stringify(templateParms),
    redirect: "follow"
  }
  )
    .then(response => {
    })
    .catch(error => {
      console.log('Chegou aqui', sheet)
      console.error('Error:', error);
    })

  if (sheet === variantContactWhiteList.CONTACT) {
    const result = await sendMessage(templateParms);
    return result;
  }
  if (sheet === variantContactWhiteList.WHIYELIST) {
    const result = await sendEmail(templateParms);
    return result;
  }
}

async function sendEmail(whiteList) {
  const templateParms = {
    email: whiteList.email,
  };

  const response = await emailjs
    .send(
      "service_52960fx",
      "template_5bl7kdu",
      templateParms,
      "xgvVf28GGBV2KHU1V"
    )
    .then((response) => {
      success();
      console.log(response.status, response.text, "EMAIL ENVIADO");
      return true;
    })
    .catch((error) => {
      console.log("Error", error);
    })

  return response;
}

async function sendMessage(whiteList) {
  const templateParms = {
    email: whiteList.email,
    from_name: whiteList.name,
    topic: whiteList.topic,
    message: whiteList.message,
  };

  const response = await emailjs
    .send(
      "service_52960fx",
      "template_qzf54du",
      templateParms,
      "xgvVf28GGBV2KHU1V"
    )
    .then((response) => {
      success();
      console.log(response.status, response.text, "EMAIL ENVIADO");
      return true;
    })
    .catch((error) => {
      console.log("Error", error);
    })

  return response;
}